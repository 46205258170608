import React, { useEffect } from "react";

const Test = () => {

    useEffect(() => {
        const audio = document.querySelector("audio");
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        if (!audio._connected) {
            const source = audioContext.createMediaElementSource(audio);
            const gainNode = audioContext.createGain();
            source.connect(gainNode);
            gainNode.connect(audioContext.destination);

            const range = document.querySelector("input[type=range]");
            range.addEventListener("input", () => {
                gainNode.gain.value = range.value;
            });

            const analyser = audioContext.createAnalyser();
            console.log(analyser);
            analyser.fftSize = 2048;
            analyser.smoothingTimeConstant = 0;

            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyser.getByteTimeDomainData(dataArray);

            gainNode.connect(analyser);

            const canvas = document.querySelector("#oscilloscope");
            const canvasCtx = canvas.getContext("2d");

            const draw = () => {
                requestAnimationFrame(draw);
                analyser.getByteTimeDomainData(dataArray);

                canvasCtx.fillStyle = "rgb(227,213,255)";
                canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = "rgb(0 0 0)";

                canvasCtx.beginPath();

                const sliceWidth = (canvas.width * 1.0) / bufferLength;
                let x = 0;

                // console.log(dataArray);
                for (let i = 0; i < bufferLength; i++) {
                    const v = dataArray[i] / 128.0;
                    const y = (v * canvas.height) / 2;

                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    } else {
                        canvasCtx.lineTo(x, y);
                    }

                    x += sliceWidth;
                }

                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                canvasCtx.stroke();
            }

            draw();

            audio._connected = true;
        }

    }, []);

    return (
        <div className="Test">
            <audio src="/1.5.2.mp3" controls></audio>
            <input type="range" min="0" max="1" step="0.01" />
            <canvas id="oscilloscope" width={300} height={300}></canvas>
            <button id="draw">Draw</button>
        </div>
    );
};

export default Test;