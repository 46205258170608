import React from "react";
import { backendRoot } from "../../contexts/BackendProps";

const MenuTopComponent = () => {
    return (
        <div className="MenuTop">
            <img src={`${backendRoot}/static/desktop/assets/img/logo.webp`} alt="logo" height="75%"/>
        </div>
    );
}

export default MenuTopComponent;