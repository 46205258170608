import React, {useEffect, useState} from "react";
import { PauseIcon, PlayIcon, VolumeUpIcon } from "./BootstrapIcons";

const AudioCustomElement = ({elementId = "current_audio", src = "", onLoadedData = e => null }) => {

    const audioRef = React.createRef();
    const [playing, setPlaying] = useState(false);
    const [audioContextState, setAudioContext] = useState(null);

    useEffect(() => {
        const audio = audioRef.current;
        const progressBar = document.querySelector("#progress_bar");
        audio.addEventListener("timeupdate", (e) => {
            progressBar && (progressBar.value = (e.target.currentTime / e.target.duration));
        });

        audio.addEventListener("play", () => {
            setPlaying(true);
        });

        audio.addEventListener("pause", () => {
            setPlaying(false);
        });
    }, [audioRef]);

    const handlePlay = e => {
        const audioContext = audioContextState? audioContextState : new (window.AudioContext || window.webkitAudioContext)();
        if(!audioContext){
            alert("Su navegador no soporta la API de AudioContext");
            return;
        }
        setAudioContext(audioContext);
        const audio = audioRef.current;
        const volumeSlide = document.querySelector("#volume_slide");

        if (!audio._connected) {
            const source = audioContext.createMediaElementSource(audio);
            const gainNode = audioContext.createGain();
            source.connect(gainNode);
            gainNode.connect(audioContext.destination);

            volumeSlide.removeEventListener("input", () => {});
            volumeSlide.addEventListener("input", (e) => {
                gainNode.gain.value = e.target.value;
            });

            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            // analyser.smoothingTimeConstant = 0;

            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyser.getByteTimeDomainData(dataArray);

            gainNode.connect(analyser);

            const canvas = document.querySelector("#oscilloscope");
            const canvasCtx = canvas.getContext("2d");

            const draw = () => {
                requestAnimationFrame(draw);
                analyser.getByteTimeDomainData(dataArray);

                canvasCtx.fillStyle = "rgb(227,213,255)";
                canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = "rgb(0 0 0)";

                canvasCtx.beginPath();

                const sliceWidth = (canvas.width * 1.0) / bufferLength;
                let x = 0;

                // console.log(dataArray);
                for (let i = 0; i < bufferLength; i++) {
                    const v = dataArray[i] / 128.0;
                    const y = (v * canvas.height) / 2;

                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    } else {
                        canvasCtx.lineTo(x, y);
                    }

                    x += sliceWidth;
                }

                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                canvasCtx.stroke();
            }

            draw();

            audio._connected = true;
        }
        audioRef.current?.play();
    };

    return (
        <div className="AudioCustomElement">
            <audio ref={audioRef} id={elementId} src={src} crossOrigin="anonymous" onLoadedData={onLoadedData} controls style={{ display: 'none' }}></audio>
            <canvas id="oscilloscope" width={300} height={100}></canvas><br />
            <div className="AudioCustomElement-controls" style={{ visibility: src ? "visible" : "hidden" }}>
                <input id="progress_bar" type="range" min="0" max="1" step="0.01" onChange={e => { if (audioRef.current) audioRef.current.currentTime = (e.target.value * audioRef.current.duration) }} />
                {playing ? <PauseIcon size="2em" onClick={e => audioRef.current.pause()} /> : <PlayIcon size="2em" onClick={handlePlay} />}
            </div>
            <br />

            <div><VolumeUpIcon size="1.2em" /> <input id="volume_slide" type="range" min="0" max="1" step="0.01" /></div>
        </div>
    );
};

export default AudioCustomElement;