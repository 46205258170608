import React from "react";
import CastingViewComponentContainer from "../components/Containers/CastingViewComponentContainer";
import Test from "../components/Layout/Test";
import Loading from "../components/Layout/Loading";
import Modal from "../components/Layout/Modal";
import Landing from "../components/Layout/Landing";
import DesktopViewComponentContainer from "../components/Containers/DesktopViewComponentContainer";

const Routing = ({ path }) => {

    const args = path.split("/");

    // console.log(args);

    return (
        <>
            {args[1] === "loading" && <Loading />}
            {args[1] === "modal" && <Modal><p>Hola</p></Modal>}
            {args[1] === "test" && <Test />}
            {args[1] === "final" && <CastingViewComponentContainer cid={args[2]} token={args[3]} final />}
            {args[1] === "resultado" && <CastingViewComponentContainer cid={args[2]} token={args[3]} final />}
            {args[1] === "view" && <CastingViewComponentContainer cid={args[2]} token={args[3]} />}
            {args[1] === "desktop" && <DesktopViewComponentContainer />}
            {args[1] === "" && <Landing />}
        </>
    );
}

export default Routing;