import { SoundwaveIcon } from "../UI/BootstrapIcons";

const Loading = ({message = "Cargando...", color="inherit"}) => {
    return (
        <div className="Modal" style={{color: color}}>
            <i className="Loading-animation"><SoundwaveIcon /></i>
            <div>{message}</div>
        </div>
    );
};

export default Loading;