import React, { useEffect, useState } from "react";
import { CheckCircleFillIcon, CheckCircleIcon, DownloadIcon, FileEarmarkMusicFillIcon, FileEarmarkMusicIcon, SoundwaveIcon } from "../UI/BootstrapIcons";
import { useFetch } from "../../lib/useFetch";
import { backendRoot } from "../../contexts/BackendProps";
import AudioCustomElement from "../UI/AudioCustomElement";
import Loading from "../Layout/Loading";
import { serviceFetch } from "../../lib/serviceFetch";
import Modal from "../Layout/Modal";

const CastingViewComponentContainer = ({ cid = "MTk", token = "09be3b86-f469-41bc-a334-705caf63e948", final = false }) => {
    const [audioPath, setAudioPath] = useState(null);

    const { loading, data } = useFetch(`casting${final ? '/final' : ''}/${cid}/${token}`);

    const [sending, setSending] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);

    const handleEnviarSeleccion = e => {
        const auditionComponentCollection = document.querySelectorAll(".AuditionDetail");
        const auditions = [];
        auditionComponentCollection.forEach(auditionComponent => {
            auditions.push({
                id: parseInt(auditionComponent.getAttribute("data-id")),
                seleccionado: auditionComponent.getAttribute("data-seleccionado") === "true"
            });
        });
        setSending(true);
        serviceFetch(`casting/${final ? 'seleccion_final' : 'seleccion'}/${cid}/${token}`, 'POST', JSON.stringify(auditions))
            .then(r => {
                if (!r.status === 500) {
                    throw new Error("Ha ocurrido un error al guardar la selección. Por favor, inténtelo nuevamente más tarde.");
                }
                return r.json();
            })
            .then(json => {
                if (!json.success) {
                    throw new Error(json.message);
                }
                setShareUrl(`${window.location.protocol}//${window.location.host}/final/${json.cid}/${json.token}`);
            })
            .catch(e => {
                alert(`La actualización de la selección no se ha podido realizar:\n${e.message}`);
            })
            .finally(() => setSending(false));
    };

    const handleFinalizarCasting = e => {
        if (!window.confirm("Al final este casting no podrá ser modificado. ¿Está seguro de finalizarlo?")) {
            return;
        }
        serviceFetch(`casting/finalizar/${cid}/${token}`, 'POST', JSON.stringify({}))
            .then(r => {
                if (!r.status === 500) {
                    throw new Error("Ha ocurrido un error al finalizar el casting. Por favor, inténtelo nuevamente más tarde.");
                }
                return r.json();
            })
            .then(json => {
                if (!json.success) {
                    throw new Error(json.message);
                }
                window.location.href = `${window.location.protocol}//${window.location.host}/resultado/${cid}/${token}`;
            })
            .catch(e => {
                alert(`La finalización del casting no se ha podido realizar:\n${e.message}`);
            })
            .finally(() => setSending(false));
    }

    return (
        <>
            {loading && <Loading />}
            {data && data.success && <div className={final?'CastingViewFinal':'CastingView'}>
                <div className="CastingView-left">
                    <CastingDetailComponent casting={data.casting} />
                    <AuditionsListComponent auditions={data.casting.audiciones} setAudioPath={setAudioPath} open={data.casting.open} />
                    {data.casting.open && <button onClick={handleEnviarSeleccion}>Guardar Selección</button>}
                </div>
                <div className="CastingView-center">
                    {<AudioCustomElement onLoadedData={e => null} src={audioPath} />}
                </div>
                <div className="CastingView-right">
                    <CastingScriptComponent script={data.casting.texto} />
                </div>
            </div>}
            {data && !data.success && <Loading message={data.message} />}
            {sending && <Loading message="Guardando selección..." />}
            {shareUrl && <Modal handleClose={e => { setShareUrl(null) }}><ShareBoxComponent shareUrl={shareUrl} final={final} handleFinalizarCasting={handleFinalizarCasting} /></Modal>}
        </>
    );
};

const CastingDetailComponent = ({ casting = {} }) => {
    return (
        <div className="CastingDetail" key={casting.id}>
            <div className="CastingDetail-Title"><SoundwaveIcon /> {casting.titulo}</div>
            <div className="CastingDetail-Description">{casting.descripcion}</div>
        </div>
    );
};

const AuditionsListComponent = ({ setAudioPath = e => null, auditions = [], open = true }) => {

    const [currentAudition, setCurrentAudition] = useState(null);

    useEffect(() => {
        if (currentAudition) {
            setAudioPath(`${backendRoot}${currentAudition.audio}`);
        }
    }, [currentAudition, setAudioPath]);


    return (
        <div className="AuditionsList" key={"audiolist"}>
            {auditions.map((audition, index) => (
                <AuditionListItemComponent key={index} isPlaying={currentAudition?.id === audition.id} elem={audition} setCurrentAudition={setCurrentAudition} open={open} />
            ))}
        </div>
    );
};

const AuditionListItemComponent = ({ elem, isPlaying = false, setCurrentAudition = e => null, open = true }) => {

    const loadAudio = () => {
        const audio = document.getElementById("current_audio");
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }
        setCurrentAudition(elem);
    };

    const handleDownload = e => {
        fetch(`${backendRoot}${elem.audio}`)
            .then(r => r.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `audicion_${String(elem.id * 7).padStart(9, '0')}.mp3`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    };

    const [isSelected, setIsSelected] = useState(elem.seleccionado);

    return (
        <div className="AuditionDetail" key={elem.id} data-id={elem.id} data-seleccionado={isSelected}>
            <i>{!isPlaying ? <FileEarmarkMusicIcon size="2em" onClick={loadAudio} /> : <FileEarmarkMusicFillIcon size="2em" />}</i>
            <div><i onClick={handleDownload}><DownloadIcon size="1.5em" /></i>&nbsp;Audición #{String(elem.id * 7).padStart(9, '0')}</div>
            <i>{isSelected ? <CheckCircleFillIcon size="2em" color="var(--green-6)" onClick={e => open && setIsSelected(false)} /> : <CheckCircleIcon size="2em" onClick={e => open && setIsSelected(true)} />}</i>

        </div>
    );
};


const CastingScriptComponent = ({ script = "" }) => {
    return <div className="CastingScript">{script}</div>;
};

const ShareBoxComponent = ({ shareUrl = "", final = false, handleFinalizarCasting = e => null }) => {

    const [copied, setCopied] = useState(false);

    const handleCopy = e => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            setCopied(true);
        });
    };
    return (
        <div className="ShareBox">
            <h1>Tu selección ha sido guardada.</h1>
            <div>Comparte el siguiente enlace para continuar con la selección final:</div>
            <div><a href={shareUrl} className="share-url">{shareUrl}</a></div>
            <div>
                <button onClick={handleCopy}>{copied ? <><CheckCircleIcon />&nbsp;Copiado</> : <>Copiar</>}</button>
                {final && <button onClick={handleFinalizarCasting}>Finalizar Casting</button>}
            </div>
        </div>
    );
};

export default CastingViewComponentContainer;