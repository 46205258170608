import React from "react";
import { serviceFetch } from "../../lib/serviceFetch";
import { useFetch } from "../../lib/useFetch";
import Loading from "../Layout/Loading";
import { CheckIcon, CopyIcon, XcircleFillIcon } from "../UI/BootstrapIcons";
import CastingListComponent from "./CastingListComponent";

const DesktopViewComponentContainer = () => {

    const { loading, data } = useFetch('casting/list');
    
    const [casting, setCasting] = React.useState(null);
    const [token_copied, setTokenCopied] = React.useState(null);

    const handleLogout = e => {
        serviceFetch('logout/')
            .then(r => {
                if (!r.ok) {
                    throw new Error(r.statusText);
                }
                return r.json();
            }).then(json => {
                window.location.href = `${window.location.origin}/`;
            }).catch(e => {
                alert(`${e.message}`);
            });
    };

    return (
        <>
            {loading && <Loading />}
            {data && <div className="DesktopViewComponentContainer">
                <CastingListComponent castings={data.castings} setCasting={setCasting} token_copied={token_copied} setTokenCopied={setTokenCopied} />
                {/* <button>Solicitar Casting</button> */}
                <button onClick={handleLogout}>Logout</button>
            </div>}
            {casting && <CastingComponent casting={casting} setCasting={setCasting} />}
        </>
    );
};



const CastingComponent = ({ casting , setCasting}) => {

    const [copied, setCopied] = React.useState(null);


    const handleCopyLink1 = e => {
        navigator.clipboard.writeText(`${window.location.origin}/view/${casting.cid}/${casting.token1}`)
            .then(e => setCopied(casting.token1)
            ).catch(e => alert('Error'));
    };

    const handleCopyLink2 = e => {
        navigator.clipboard.writeText(`${window.location.origin}/final/${casting.cid}/${casting.token2}`)
            .then(e => setCopied(casting.token2)
            ).catch(e => alert('Error'));
    };
    const handleClose = e =>setCasting(null);
    return <div class="CastingComponent">
        <div>
            <i onClick={handleClose}><XcircleFillIcon /></i>
        </div>
        <div className="estado">
            <span className={casting.estado}>{casting.estado}</span>
        </div>
        <div>
            <label>Título</label>
            <input type="text" value={casting.titulo} readOnly />
        </div>

        <div>
            <label>Descripción</label>
            <textarea value={casting.descripcion} readOnly />
        </div>
        <div>
            <label>Texto</label>
            <textarea value={casting.texto} readOnly />
        </div>
        <div>
            <label>Deadline</label>
            <input type="text" value={casting.deadline} readOnly />
        </div>
        <div>
            <label>Género</label>
            <input type="text" value={casting.genero} readOnly />
        </div>
        <div>
            <label>Acento</label>
            <input type="text" value={casting.acento} readOnly />
        </div>
        <div>
            <label>Requisitos</label>
            <textarea value={casting.requisitos} readOnly />
        </div>
        <div>
            <label>Presupuesto</label>
            <input type="text" value={`${casting.presupuesto?.toLocaleString()} ${casting.moneda?.nombre}`} readOnly />
        </div>
        <div>
            <label>Derechos</label>
            <input type="text" value={casting.derechos?.nombre} readOnly />
        </div>
        <div>
            <label>Tiempo de uso</label>
            <input type="text" value={casting.tiempo_uso?.nombre} readOnly />
        </div>
        <div>
            <label>Tipo</label>
            <input type="text" value={casting.tipo?.nombre} readOnly />
        </div>
        <div className="enlace">{casting.token1 && <>
            <span className="link1">
                <a href={`${window.location.origin}/view/${casting.cid}/${casting.token1}`}>Selección Inicial</a>
            </span>
            <i onClick={handleCopyLink1}><CopyIcon /></i>
            <i style={{ visibility: copied === casting.token1 ? 'visible' : 'hidden' }}><CheckIcon /></i>
        </>}</div>
        <div className="enlace">{casting.token2 && <>
            <span className="link2">
                <a href={`${window.location.origin}/final/${casting.cid}/${casting.token2}`}>Selección Final</a>
            </span>
            <i onClick={handleCopyLink2}><CopyIcon /></i>
            <i style={{ visibility: copied === casting.token2 ? 'visible' : 'hidden' }}><CheckIcon /></i>
        </>}</div>
    </div>;
};

export default DesktopViewComponentContainer;