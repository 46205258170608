import { XcircleFillIcon } from "../UI/BootstrapIcons";


const Modal = ({children, handleClose = e => {document.querySelector(".Modal").remove()}}) => {

    return (
        <div className="Modal">
            <div className="Modal-content">
                <i className="Modal-content-close" onClick={handleClose}><XcircleFillIcon /></i>
                {children}
            </div>
        </div>
    );
};

export default Modal;