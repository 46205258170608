import './App.css';
import MenuTopComponent from './components/Layout/MenuTopComponent';
import Routing from './routes/Routing';

function App() {


  return (
    <>
      <header>
        <MenuTopComponent />
      </header>
      <div className="GoDemos">
        <Routing path={window.location.pathname} />
      </div>
    </>
  );
}

export default App;
