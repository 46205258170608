import { useState, useEffect } from "react";
import { serviceRoot } from "../contexts/BackendProps"

// eslint-disable-next-line
export function useFetch(url, params = {}, listenState = []) {
    const [httpStatus, setHttpStatus] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    listenState.push(url);

    useEffect(() => {
        setLoading(true);
        params.credentials = "include";
        // get CSRF token from cookie
        // console.log("cookies:" + document.cookie); 
        if (document.cookie?.split("csrftoken").length > 1) {
            let splitted = document.cookie.split("; ")
            let aux = splitted.find((row) => row.startsWith("csrftoken="));
            let csrftoken = aux.split("=")[1];
            params.headers = {
                "X-CSRFToken": csrftoken,
                // "SameSite": false 
            };
            params.mode = "cors";
        }
        else
        {
            let csrftoken = localStorage.getItem("csrf_token");
            if(csrftoken){
                params.headers = {
                    "X-CSRFToken": csrftoken,
                    // "SameSite": false
                };
                params.mode = "cors";
            }
        }

        fetch(`${serviceRoot}/${url}`, params)
            .then((res) => {
                setHttpStatus(res.status);
                return res.json();
            })
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, listenState);

    return { loading, data, error, httpStatus };
}