import React, { useEffect } from "react";
import { CheckIcon, CopyIcon, FileTextIcon } from "../UI/BootstrapIcons";

const CastingListComponent = ({ castings = [], setCasting = e => null, token_copied = null, setTokenCopied = e=>null }) => {
    return (<div className="CastingListComponent">
        {castings.map(item => <CastingItemComponent
            key={item.id} 
            item={item} 
            setTokenCopied={setTokenCopied}
            setCasting={setCasting}
            token_copied={token_copied} />)}
    </div>);
};

const CastingItemComponent = ({ item, token_copied = null, setTokenCopied = e => null, setCasting }) => {

    const [copied, setCopied] = React.useState(token_copied);

    useEffect(() => {
        setCopied(token_copied);
    }, [token_copied]);

    const handleCopyLink1 = e => {
        navigator.clipboard.writeText(`${window.location.origin}/view/${item.cid}/${item.token1}`)
            .then(e => setTokenCopied(item.token1)
            ).catch(e => alert('Error'));
    };

    const handleCopyLink2 = e => {
        navigator.clipboard.writeText(`${window.location.origin}/final/${item.cid}/${item.token2}`)
            .then(e => setTokenCopied(item.token2)
            ).catch(e => alert('Error'));
    };

    const handleView = e => {
        setCasting(item);
    };

    return <div className="CastingItemComponent">
        <div key={item.id} onClick={handleView}><FileTextIcon /> {item.titulo}</div>
        <div className="estado"><span className={item.estado}>{item.estado}</span></div>
        <div>{item.derechos?.nombre}</div>
        <div>{item.presupuesto?.toLocaleString()} {item.moneda?.nombre}</div>
        <div>{item.tiempo_uso?.nombre}</div>
        <div>{item.tipo?.nombre}</div>
        <div className="enlace">{item.token1 && <>
            <span className="link1">
                <a href={`${window.location.origin}/view/${item.cid}/${item.token1}`}>Selección Inicial</a>
            </span>
            <i onClick={handleCopyLink1}><CopyIcon /></i>
            <i style={{ visibility: copied === item.token1 ? 'visible' : 'hidden' }}><CheckIcon /></i>
        </>}</div>
        <div className="enlace">{item.token2 && <>
            <span className="link2">
                <a href={`${window.location.origin}/final/${item.cid}/${item.token2}`}>Selección Final</a>
            </span>
            <i onClick={handleCopyLink2}><CopyIcon /></i>
            <i style={{ visibility: copied === item.token2 ? 'visible' : 'hidden' }}><CheckIcon /></i>
        </>}</div>

    </div>;
};

export default CastingListComponent;