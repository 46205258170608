import React from "react";
import { serviceFetch } from "../../lib/serviceFetch";
import Loading from "./Loading";


const Landing = () => {
    const VERIFICANDO = -1;
    const LANDING = 0;
    const SOLICITUD = 1;
    const [section, setSection] = React.useState(VERIFICANDO);

    const checkCredentials = () => {
        serviceFetch('check_login/')
        .then(r => {
            if (!r.ok) {
                throw new Error(r.statusText);
            }
            return r.json();
        }).then(json => {
            window.location.href = `${window.location.origin}/desktop/`;
        }).catch(e => {
            setSection(LANDING);
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        setSection(VERIFICANDO);
        serviceFetch('signin/','POST', new FormData(e.target))
        .then(r => {
            if (!r.ok) {
                throw new Error(r.statusText);
            }
            return r.json();
        }).then(json =>{
            setSection(SOLICITUD);
            window.location.href = `${window.location.origin}/desktop/`;
        }).catch(e => {
            alert(`${e.message}`);
            setSection(SOLICITUD);
        }).finally(() => {
            setSection(SOLICITUD);
        });
    };

    return (<>

        <div className="Landing">
            {section === VERIFICANDO && 
                <Loading message="Verificando credenciales..." color="white">{checkCredentials()}</Loading>
            }
            {section === LANDING && <>
                <div className="Landing-indicadores">
                    <Usuarios />
                    <Paises />
                    <Configuraciones />
                </div>
                <div className="Landing-mensaje">
                    <p>Ya somos más de 800 usuarios registrados y cada día más personas se suman a nuestra plataforma para acceder a oportunidades de casting únicas.</p>
                    <p>Gracias a nuestra amplia red de usuarios activos, puedes tener la seguridad de encontrar a la persona adecuada para tu proyecto de forma rápida y eficiente.</p>
                    <h2>¡Cotiza tu Casting con nosotros y encuentra la voz que estás buscando!</h2>
                    <button onClick={e => setSection(SOLICITUD)}>Ingresa</button>
                </div>
            </>}
            {section === SOLICITUD && <>
                <div className="Landing-solicitud">
                    <p>Inicie sesión con su cuenta de go-demos.com</p>
                    <hr/>
                    <form onSubmit={handleSubmit} method="POST">
                        <label>Correo electrónico</label>
                        <input type="email" name="username" />
                        <label>Contraseña</label>
                        <input type="password" name="password" />
                        <button type="submit">Iniciar Sesión</button>
                    </form>
                </div>
            </>}
        </div>
    </>
    );
};

const Usuarios = ({ width = "100%" }) => {
    return (
        <svg viewBox="0 0 100 100" width={width}>
            <circle cx="50" cy="50" r="40" stroke="var(--secondary-9)" strokeWidth="10" fill="var(--secondary-1)" />
            <text x="50" y="50" textAnchor="middle" fontWeight="bold" fill="var(--secondary-9)" fontSize="15" dy="-0.3em">
                <tspan fontSize="1.3em">+800</tspan>
                <tspan dx={-48} dy="1.1em" >usuarios</tspan>
            </text>
        </svg>
    );
};

const Paises = ({ width = "100%" }) => {
    return (
        <svg viewBox="0 0 100 100" width={width}>
            <circle cx="50" cy="50" r="40" stroke="var(--green-9)" strokeWidth="10" fill="var(--green-1)" />
            <text x="50" y="50" textAnchor="middle" fontWeight="bold" fill="var(--green-9)" fontSize="15" dy="-0.3em">
                <tspan fontSize="1.3em">18</tspan>
                <tspan dx={-32} dy="1.1em" >países</tspan>
            </text>
        </svg>
    );
};

const Configuraciones = ({ width = "100%" }) => {
    return (
        <svg viewBox="0 0 100 100" width={width}>
            <circle cx="50" cy="50" r="40" stroke="var(--primary-9)" strokeWidth="10" fill="var(--primary-1)" />
            <text x="50" y="50" textAnchor="middle" fontWeight="bold" fill="var(--primary-9)" fontSize="15" dy="-0.1em">
                <tspan fontSize="1.3em">+200</tspan>
                <tspan dx={-52} dy="1.1em" fontSize="0.6em" >demos posibles</tspan>
            </text>
        </svg>
    );
};

export default Landing;