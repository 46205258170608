export const backendRoot = 'https://api.go-casting.com';
export const serviceRoot = `${backendRoot}/service`;
export const chartColors = [
    "#0000FF",
    "#00CC00",
    "#FF9FDA",
    "#FF7F00",
    "#FF0000",
    "#8e44ad",
    "#9400D3",
    "#4B0082",
    "#2980b9",
    "#3498db",
    "#1abc9c",
    "#16a085",
    "#27ae60",
    "#2ecc71",
    "#f1c40f",
    "#f39c12",
    "#e67e22",
    "#d35400",
    "#c0392b",
    "#e74c3c",
    "#ecf0f1",
    "#bdc3c7",
    "#95a5a6",
    "#7f8c8d",
];
